<template>
  <div>
    <b-overlay :show="isProcessing">
      <validation-observer ref="campaignForm">
        <!-- Name -->
        <validation-provider
          #default="{ errors }"
          name="campaign name"
          rules="required"
        >
          <b-form-group label="Campaign Name">
            <b-form-input
              v-model="form.name"
              placeholder="Campaign Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Purpose -->
        <validation-provider
          #default="{ errors }"
          name="purpose"
          rules="required"
        >
          <b-form-group label="Purpose of Social Campaign">
            <b-form-input
              v-model="form.purpose"
              placeholder="Purpose"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Phases -->
        <div
          v-for="phase, index in form.phases"
          :key="`phase-${index}`"
        >
          <validation-provider
            #default="{ errors }"
            :name="`phase ${index + 1}`"
            rules="required"
          >
            <b-form-group :label="`Phase ${index + 1}`">
              <b-input-group>
                <b-form-input
                  v-model="phase.title"
                  placeholder="Phase Description"
                />
                <b-input-group-append v-if="index > 2">
                  <b-button
                    v-b-tooltip="'Remove Phase'"
                    variant="danger"
                    size="sm"
                    @click="form.phases.splice(index, 1)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>

        <!-- <b-row class="align-items-center">
          <b-col md="5">
            <b-button
              variant="outline-primary"
              @click="form.phases.push({ title: '' })"
            >
              <feather-icon icon="PlusIcon" />
              Add Phase
            </b-button>
          </b-col>

          <b-col md="7">
            <b-form-group label="Starting Date">
              <b-form-datepicker v-model="form.createdAt" />
            </b-form-group>
          </b-col>
        </b-row> -->
        <hr>
        <div>
          <b-button
            v-if="!(facebookAccounts || isLoggedIn)"
            v-b-tooltip.hover.top="'Login with Facebook'"
            variant="primary"
            class="h-100 rounded-0 mb-1 mr-1"
            size="sm"
            @click="facebookLogin"
          >
            <feather-icon
              icon="FacebookIcon"
            />
            Login with Facebook
          </b-button>
          <b-badge
            v-else
            variant="success"
            pills
          >
            <feather-icon
              icon="FacebookIcon"
            />
            Account Added
          </b-badge>
          <!-- <b-button
            v-else
            v-b-tooltip.hover.top="'Logout'"
            variant="danger"
            class="h-100 rounded-0 mb-1"
            size="sm"
            @click="facebookLogout"
          >
            <feather-icon
              icon="FacebookIcon"
            />
            Logout
          </b-button> -->
        </div>
      </validation-observer>
    </b-overlay>

    <!-- Buttons -->
    <b-row
      class="border-top mt-1 pt-1 align-items-center"
      :class="campaignMode === 'update' ? 'justify-content-between' : 'justify-content-end'"
    >
      <!-- Delete Button -->
      <b-col
        v-if="campaignMode === 'update'"
        cols="2"
      >
        <b-button
          v-b-tooltip.hover="'Delete Campaign'"
          variant="danger"
          :disabled="isProcessing"
          @click="$bvModal.show('delete-campaign-confirm-modal')"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </b-col>

      <b-col
        class="d-flex align-items-center justify-content-end"
        :cols="campaignMode === 'update' ? '10' : '12'"
      >
        <!-- Cancel Button -->
        <b-button
          :disabled="isProcessing"
          class="p-1 d-none d-md-block"
          @click="resetFormAndClose"
        >
          {{ $t('Cancel') }}
        </b-button>

        <!-- Save Button -->
        <b-button
          variant="primary"
          class="ml-1 p-1"
          :disabled="isProcessing || facebook.length === 0"
          @click="campaignMode === 'create' ? handleOk() : $bvModal.show('update-campaign-confirm-modal')"
        >
          <b-spinner
            v-show="isProcessing"
            small
          />
          <span v-if="campaignMode === 'create'">
            {{ isProcessing ? value === 0? $t('Thinking') : $t('Generating') : campaign ? 'Update and Generate' : 'Create and Generate' }}
          </span>
          <span v-else>
            {{ isProcessing ? value === 0? $t('Thinking') : $t('Generating') : campaign ? 'Update and Regenerate' : 'Create and Regenerate' }}
          </span>

          <span v-if="isProcessing && value > 0">
            {{ `${value} / ${max }` }}
          </span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Update Campaing Confirmation Modal -->
    <confirm-modal
      modal="update-campaign-confirm-modal"
      title="Update Campaign Confirmation"
      description="All the current posts will be deleted and new posts will be generated. Are you sure you want to update this campaign?"
      ok-title="Confirm"
      ok-variant="danger"
      @confirm="handleOk"
    />

    <!-- Delete Campaign Confirmation Modal -->
    <confirm-modal
      modal="delete-campaign-confirm-modal"
      title="Delete Campaign Confirmation"
      description="Are you sure you want to delete this campaign?"
      ok-title="Delete"
      ok-variant="danger"
      @confirm="handleDelete"
    />
  </div>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BFormGroup, BFormInput, BOverlay, BButton, BInputGroup, BInputGroupAppend, BSpinner, VBTooltip, BBadge, BRow, BCol,
  // BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'
import EventBus from '@/event-bus'
import ConfirmModal from '@/views/Wallet/components/reusables/ConfirmModal.vue'

import useJwt from '@/auth/jwt/useJwt'
import {
  getCurrentCampaign, setCurrentCampaign, getCurrentCampaignProgresses, setCurrentCampaignProgresses,
} from '../useSocialCampaign'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,
    BButton,
    // BFormDatepicker,
    BRow,
    BCol,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    ConfirmModal,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      form: {
        phases: [
          { title: '' },
          { title: '' },
          { title: '' },
        ],
        createdAt: new Date(),
      },
      facebook: [],
      isProcessing: false,
      isFBReady: false,
      isLoggedIn: false,
      // appSecret: 'c59fc7b96a5b96418598b512df54c550', // TODO: Move to config
      appId: '2262134934143019', // TODO: Move to config
      longLivedToken: null,
      linkedInModal: false,
      linkedInAccessToken: null,
      linkedInPages: [],
      selectedLinkedInPages: [],
    }
  },
  computed: {
    companyUid() {
      return this.$store.state.project.selectedCompany
    },
    campaign() {
      return getCurrentCampaign()
    },
    campaignInProgress() {
      return getCurrentCampaignProgresses(this.campaign?.uuid)
    },
    campaignMode() {
      return this.campaign && this.campaign.generations?.total > 0 ? 'update' : 'create'
    },
    value() {
      return this.campaignInProgress?.progress ?? 0
    },
    max() {
      return this.campaignInProgress?.total ?? 27
    },
    facebookAccounts() {
      return this.form?.linkedAccounts?.filter(linkedAccount => linkedAccount.account.__typename === 'CampaignFacebookSetting').length
    },
  },
  watch: {
    campaign: {
      handler(val) {
        if (val) {
          this.form = val
        }
      },
      immediate: true,
    },
    value(val) {
      if (val && val === this.max) {
        this.isProcessing = false
      }
    },
  },
  mounted() {
    this.initFacebookSDK()

    if (window.location.search.includes('code')) {
      this.handleCallback()
    }
  },
  methods: {
    ...mapActions('socialCampaign', ['updateCampaignEvent']),
    resetFormAndClose() {
      this.form = {
        phases: [{
          title: '',
        }],
      }
      this.$bvModal.hide('event-details-modal')
    },
    async handleOk(bvModal) {
      try {
        // Prevent default modal behavior
        if (bvModal) bvModal.preventDefault()

        // Validate the form using $refs
        const success = await this.$refs.campaignForm.validate()

        if (success) {
          this.isProcessing = true

          // Prepare params
          const params = {
            companyUid: this.companyUid,
            input: {
              name: this.form.name,
              purpose: this.form.purpose,
            },
            phases: this.form.phases.map(({ __typename, ...phase }) => ({
              ...phase,
            })),
          }

          // Determine if this is a create or update operation
          let api = 'createCampaign'
          if (this.form.uuid) {
            params.campaignUid = this.form.uuid
            api = 'updateCampaign'
          }

          // Call the API
          const response = await useApollo.company[api](params)

          // Handle success
          this.showSuccess(response.data[api].message)

          // Add campaign Facebook settings (await here to ensure this runs after the previous block)
          if (this.facebook.length > 0) {
            await this.addCampaignFacebookSettings(response.data[api].data.uuid)
          }

          const { uuid } = response.data[api]?.data
          const { batch } = response.data[api]

          // Set current campaign details
          setCurrentCampaign({
            ...this.form,
            uuid,
            batch,
          })
          setCurrentCampaignProgresses({ uuid, batch }, 'add')

          if (!this.form.uuid) {
            this.$emit('refetch-calendar', uuid)
          } else {
            // If updating an existing campaign, emit event to update the campaign event
            const payload = { ...this.form, title: this.form.name }
            this.updateCampaignEvent(payload)
          }

          // Reset the form after successful submission
          this.$refs.campaignForm.reset()
        }
      } catch (error) {
        // Show error message if something went wrong
        this.showErrorMessage(error)
      } finally {
        // Ensure this part always runs after either success or failure
        setTimeout(() => {
          EventBus.$emit('check-progress', this.campaign.uuid)
        }, 15000)
      }
    },
    handleDelete() {
      this.isProcessing = true
      useApollo.company.deleteCampaign({ campaignUid: this.campaign.uuid })
        .then(response => {
          this.showSuccess(response.data.deleteCampaign.message)
          this.$emit('refetch-calendar', this.campaign.uuid)
          this.$bvModal.hide('event-details-modal')
        }).catch(error => {
          this.showErrorMessage(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    async addCampaignFacebookSettings(campaignUid) {
      try {
        await useApollo.company.addCampaignFacebookSettings({
          campaignUid,
          input: this.facebook,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    initFacebookSDK() {
      return new Promise(resolve => {
        window.fbAsyncInit = () => {
          FB.init({
            appId: this.appId, // Replace with your app ID
            cookie: true,
            xfbml: true,
            version: 'v16.0',
          })

          FB.AppEvents.logPageView()
          this.checkLoginState()
          resolve()
        };

        (function (d, s, id) {
          let js
          const fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) return
          js = d.createElement(s)
          js.id = id
          js.src = 'https://connect.facebook.net/en_US/sdk.js'
          fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
      })
    },
    checkLoginState() {
      FB.getLoginStatus(response => {
        this.statusChangeCallback(response)
      })
    },
    async statusChangeCallback(response) {
      if (response.status === 'connected') {
        this.isLoggedIn = true
        const shortLivedToken = response.authResponse.accessToken
        await this.getLongLivedToken(shortLivedToken)
        await this.getUserInfo()
        await this.getPages()
      } else {
        this.isLoggedIn = false
        this.facebook = []
      }
    },
    facebookLogin() {
      FB.login(
        response => {
          // if (response.authResponse) {
          //   console.log('authResponse', response.authResponse.accessToken)
          //   const shortLivedToken = response.authResponse.accessToken
          //   await this.getLongLivedToken(shortLivedToken) // Call to exchange it for a long-lived token
          // }
          this.statusChangeCallback(response)
        },
        // { scope: 'public_profile,email,pages_show_list' },
        { scope: 'email, pages_manage_instant_articles, pages_show_list, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_posts, pages_manage_engagement, public_profile' },
      )
    },
    facebookLogout() {
      FB.logout(response => {
        this.statusChangeCallback(response)
      })
    },
    async getLongLivedToken(shortLivedToken) {
      const params = {
        fb_exchange_token: shortLivedToken,
      }
      try {
        const response = await useJwt.getFacebookLongLivedToken(params)
        // console.log('long-lived token', response.data.access_token)
        console.log('long-lived-token', response)
        this.longLivedToken = response.data.access_token
      } catch (error) {
        console.log('Long Lived Token Error', error)
      }
    },
    async getUserInfo() {
      FB.api('/me', response => {
        this.userName = response.name
      })
    },
    async getPages() {
      FB.api('/me/accounts', { access_token: this.longLivedToken }, response => {
        if (response && !response.error) {
          this.facebook = response.data.map(page => ({
            pageId: page.id,
            pageName: page.name,
            pageAccessToken: page.access_token, // Get the access token for each page
            // longLivedToken: this.longLivedToken,
          }))
        }
      })
    },

    // LinkedIn
    linkedinLogin() {
      // LinkedIn OAuth URL
      const clientId = '77s599u21kjqzz' // LinkedIn client ID
      const redirectUri = 'http://localhost:8080/social-media-campaign?callback=linkedin' // This page’s URL
      const state = Math.random().toString(36).substring(2) // Random state parameter for security
      const scope = 'r_organization_social rw_organization_admin'

      // Save state in localStorage to verify in callback
      localStorage.setItem('linkedin_oauth_state', state)

      // LinkedIn authorization URL with parameters
      const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${scope}&state=${state}`
      window.location.href = authUrl
    },
    async handleCallback() {
      const urlParams = new URLSearchParams(window.location.search)
      const code = urlParams.get('code')
      const state = urlParams.get('state')
      // Validate state for security
      if (state !== localStorage.getItem('linkedin_oauth_state')) {
        console.log('Invalid state parameter. Possible CSRF detected.')
        return
      }

      // Exchange authorization code for access token via backend
      if (code) {
        try {
          const params = {
            code,
          }
          const response = await useJwt.exchangeLinkedInToken(params)

          const data = await response.data
          console.log('data', data.access_token)
          this.linkedInAccessToken = data.access_token
          this.fetchPages()
        } catch (error) {
          console.error('Failed to get access token:', error)
        }
      }
    },
    async fetchPages() {
      if (!this.linkedInAccessToken) return

      // Fetch LinkedIn organization pages via backend proxy
      try {
        const pageParams = {
          accessToken: this.linkedInAccessToken,
        }
        console.log(pageParams)
        const response = await useJwt.getLinkedInPages(pageParams)
        const { data } = response
        if (data.status === 429) {
          this.showErrorMessage(data.message)
        } else {
          console.log(data)
          this.linkedInPages = data
        }
        this.linkedInModal = true
      } catch (error) {
        console.error('Failed to fetch LinkedIn pages:', error)
      }
    },
  },
}
</script>
