<template>
  <b-modal
    id="sc-edit-post-modal"
    class="position-relative"
    size="lg"
    ok-title="Update"
    no-close-on-esc
    no-close-on-backdrop
    :ok-disabled="isProcessing || imageGenerating"
    :cancel-disabled="isProcessing || imageGenerating"
    @ok="updatePost"
    @hidden="closeModal"
  >
    <template #modal-title>
      <h6 class="mb-0">
        {{ `Edit Post ${post?.title ? `- ${post.title}` : ''}` }}
      </h6>
      <small class="text-muted">{{ post.scheduledAt ? FORMAT_DATE(post.scheduledAt, 'DD MMM YYYY') : '' }}</small>
    </template>

    <b-overlay :show="isProcessing">
      <validation-observer ref="scPostForm">
        <!-- Post Preview -->
        <b-row>
          <b-col md="6">
            <!-- Title -->
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-group label="Title">
                <b-form-input
                  v-model="form.title"
                  placeholder="Post Title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Post Description -->
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-group label="Description">
                <b-form-input
                  v-model="form.description"
                  placeholder="Description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Post Text -->
            <validation-provider
              #default="{ errors }"
              name="text"
              rules="required"
            >
              <b-form-group label="Text">
                <b-form-textarea
                  v-model="form.post"
                  placeholder="Text"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <!-- Image -->
            <div class="image-upload-container">
              <label
                for="post-image"
                class="col-form-label w-100 pt-0"
              >
                <div
                  class="img-preview-container border rounded-lg centralize"
                  :class="{ 'img-preview-container-shown cursor-pointer': !imageGenerating }"
                  @click.prevent="previewImage"
                >
                  <!-- Image Preview -->
                  <b-overlay :show="imageGenerating">
                    <img
                      v-if="form.imageUrl || currentImage"
                      :src="currentImage ? currentImage : getImageUrl(form.imageUrl)"
                      alt=""
                      class="preview-img"
                    >
                  </b-overlay>
                  <!-- Icons displayed on hover -->
                  <div
                    v-if="form.imageUrl || currentImage"
                    class="icon-overlay"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="36"
                      class="icon-preview"
                    />
                    <feather-icon
                      icon="UploadIcon"
                      size="36"
                      class="icon-upload"
                      @click.prevent.stop="triggerFileInput"
                    />
                  </div>
                  <!-- Default Upload Icon if no image is selected -->
                  <feather-icon
                    v-else
                    icon="UploadIcon"
                    size="72"
                  />
                </div>
              </label>
              <b-form-file
                id="post-image"
                v-model="form.image"
                :disabled="imageGenerating"
                class="d-none"
                :state="Boolean(form.image)"
                @change="handleFileChange"
              />
            </div>

            <!-- Image prompt -->
            <validation-provider
              #default="{ errors }"
              name="imagePrompt"
              rules="required"
            >
              <b-form-group label="Image Prompt">
                <b-input-group>
                  <b-form-input
                    v-model="form.imagePrompt"
                    placeholder="Image Prompt"
                  />
                  <b-input-group-append>
                    <!-- Regenerate Image -->
                    <span v-b-tooltip.hover.top="form.imagePrompt && !isProcessing ? 'Regenerate Image' : ''">
                      <b-button
                        :disabled="!form.imagePrompt || isProcessing"
                        variant="outline-warning"
                        class="h-100 rounded-0"
                        size="sm"
                        @click="regenerateImage"
                      >
                        <feather-icon
                          icon="RotateCwIcon"
                          :class="{ 'spin-icon': imageGenerating }"
                        />
                      </b-button>
                    </span>

                    <!-- Save Image -->
                    <span v-b-tooltip.hover.top="form.imagePrompt && !isProcessing ? 'Save Image' : ''">
                      <b-button
                        v-if="currentImage"
                        variant="outline-success"
                        class="h-100 rounded-0"
                        size="sm"
                        :disabled="isProcessing || imageGenerating"
                        @click="updatePost(null, false)"
                      >
                        <feather-icon icon="CheckIcon" />
                      </b-button>
                    </span>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>

            </validation-provider>
          </b-col>
        </b-row>

        <!-- Prev / Next Post Buttons -->
        <span
          v-b-tooltip.hover="IS_MOBILE() || shouldDisablePreviousButton ? '' : 'Previous Post'"
          class="position-absolute left-btn"
        >
          <b-button
            v-if="currentCampaign"
            variant="none"
            :disabled="shouldDisablePreviousButton"
            @click="() => { currentImage = null; getPreviousPost(currentPostDate, currentCampaign) }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="64"
            />
          </b-button>
        </span>

        <span
          v-b-tooltip.hover="IS_MOBILE() || shouldDisableNextButton ? '' : 'Next Post'"
          class="position-absolute right-btn"
        >
          <b-button
            v-if="currentCampaign"
            variant="none"
            :disabled="shouldDisableNextButton"
            @click="() => { currentImage = null; getNextPost(currentPostDate, currentCampaign) }"
          >
            <feather-icon
              icon="ChevronRightIcon"
              size="64"
            />
          </b-button>
        </span>

      </validation-observer>
    </b-overlay>

    <!-- Image Preview Modal -->
    <CoolLightBox
      :items="source"
      :index="slide"
      :enable-scroll-lock="false"
      @close="() => {slide = null; source = []}"
    />
  </b-modal>
</template>

<script>
import {
  BModal, BFormGroup, BFormInput, BFormTextarea, BFormFile, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import useApollo from '@/plugins/graphql/useApollo'
import { regenerateSocialMediaCampaignPostImage } from '@/auth/jwt/useDatablock'
import CoolLightBox from 'vue-cool-lightbox'
import { mapGetters } from 'vuex'
import moment from 'moment'
import {
  getCurrentPost, setCurrentPost, getPreviousPost, getNextPost,
} from '../useSocialCampaign'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    CoolLightBox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      form: {},
      currentImage: null,
      isProcessing: false,
      imageGenerating: false,
      imageBase64: null,
      isDialogOpen: false,
      slide: null,
      source: [],
    }
  },
  computed: {
    ...mapGetters('socialCampaign', { campaignEvents: 'getCampaignEvents' }),
    post() {
      return getCurrentPost()
    },
    currentCampaign() {
      if (this.post) return this.campaignEvents.find(event => event.uuid === this.post.campaignUid)
      return null
    },
    sortedCampaignPost() {
      return this.currentCampaign?.generations?.data
        .map(post => moment(post.scheduledAt).toDate())
        .sort((a, b) => a - b)
    },
    currentCampaignStartingDate() {
      return this.sortedCampaignPost?.[0]
    },
    currentCampaignEndingDate() {
      return this.sortedCampaignPost?.[this.currentCampaign?.generations.total - 1]
    },
    currentPostDate() {
      const currentPost = this.currentCampaign?.generations?.data.find(post => post.uuid === this.post.uuid)
      return currentPost?.scheduledAt
    },
    shouldDisablePreviousButton() {
      return this.isProcessing || moment(this.currentPostDate).isSame(this.currentCampaignStartingDate)
    },
    shouldDisableNextButton() {
      return this.isProcessing || moment(this.currentPostDate).isSame(this.currentCampaignEndingDate)
    },
  },
  watch: {
    post: {
      handler(val) {
        if (val) {
          this.form = val
        }
      },
      immediate: true,
    },
  },
  methods: {
    moment,
    getPreviousPost,
    getNextPost,
    updatePost(bvModal, hideModal = true) {
      if (bvModal) bvModal.preventDefault()
      this.$refs.scPostForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const {
            uuid,
            scheduledAt,
            title,
            description,
            post,
            imagePrompt,
            jsonData,
          } = this.form
          const data = {
            campaignGenerationUid: uuid,
            scheduledAt,
            input: {
              title,
              description,
              post,
              imagePrompt,
              jsonData,
            },
          }
          // Append image data if a new image was uploaded
          if (this.currentImage) {
            data.input.imageData = this.imageBase64 // Assuming `image` is the uploaded image file
          }
          useApollo.company.updateCampaignGeneration(data)
            .then(response => {
              this.showSuccess(response.data.updateCampaignGeneration.message)
              this.$emit('refetch-calendar', this.post.uuid)
            }).catch(error => {
              this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
              this.imageBase64 = null
              this.imageGenerating = false

              if (hideModal) {
                this.$bvModal.hide('sc-edit-post-modal')
              }

              setTimeout(() => {
                this.currentImage = null
              }, 10000)
            })
        }
      })
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.currentImage = URL.createObjectURL(file)
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imageBase64 = e.target.result // Store Base64 string in form
        }
        reader.readAsDataURL(file) // Convert file to Base64
      }
    },
    getImageUrl(image) {
      return `${process.env.VUE_APP_STORAGE_URL}${image}`
    },
    regenerateImage() {
      this.imageGenerating = true
      const params = {
        message: this.form.imagePrompt,
        title: this.form.title,
        description: this.form.description,
        post: this.form.post,
        photo_style: 'photorealistic',
      }
      regenerateSocialMediaCampaignPostImage(params)
        .then(response => {
          const imageUrl = response.data.message.result.output[0]
          this.currentImage = imageUrl

          return fetch(imageUrl).then(res => {
            if (!res.ok) {
              throw new Error('Network response was not ok')
            }
            return res.blob()
          })
        })
        .then(blob => {
          const reader = new FileReader()
          reader.onloadend = e => {
            const { result } = e.target
            if (result.startsWith('data:binary/octet-stream;base64,')) {
              // Replace it with image/png
              this.imageBase64 = result.replace('data:binary/octet-stream;base64,', 'data:image/png;base64,')
            }
          }
          reader.readAsDataURL(blob) // Convert Blob to Base64
        }).catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.imageGenerating = false
        })
    },
    closeModal() {
      this.currentImage = null
      setCurrentPost(null)
    },
    triggerFileInput() {
      document.getElementById('post-image').click()
    },
    previewImage() {
      if (this.currentImage || this.form.imageUrl) {
        this.source.push(this.currentImage ? this.currentImage : this.getImageUrl(this.form.imageUrl))
        this.slide = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/pages/social-campaign.scss';
</style>
